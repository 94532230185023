import { useEffect, useState } from 'react';
import '../style/applications.css';
import TopBar from './TopBar';
import Footer from './Footer';

import ReactGA from 'react-ga4';

export default function Applications() {
    const [width, setWidth] = useState(0);

    const items = [
        {
            title: "Mobile",
            content: "We create intuitive and engaging mobile apps that provide seamless experiences across all devices.",
            image: "mobile.png",
        },
        {
            title: "Web",
            content: "Our web applications are designed for performance, scalability, and user-friendly interfaces, ensuring optimal interaction.",
            image: "web.png",
        },
        {
            title: "Desktop",
            content: "We develop robust desktop applications tailored to meet your business needs, offering high performance and reliability.",
            image: "desktop.png",
        },
        {
            title: "APIs",
            content: "Our API solutions facilitate smooth integration between different software systems, enhancing interoperability and functionality.",
            image: "apis.png",
        },
    ];

    function updateWidth() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        document.getElementById("applications")?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        updateWidth();
        window.addEventListener('resize', () => {
            updateWidth();
        });
        ReactGA.send({hintType: 'pageview', page: '/fields/applications', title: 'Applications'});
    }, []);

    return <div className='pt-5'>
        <TopBar />
        <section id="applications" className="container-fluid py-5 overflow-hidden d-flex flex-column justify-content-around position-relative">

            <div className="top-title mb-3 p-4">
                We design, implement, and deploy <span className="highlight">Applications </span>
                based on your needs
            </div>

            {
                items.map((item, idx) => {
                    return <div key={item.image} className="row p-4">
                        {(idx % 2 === 0 || width < 992) &&
                            <div className="col col-12 col-lg-6 my-2">
                                <div className="item-title text-center fw-bolder">{item.title}</div>
                                <div className="col col-12 col-lg-6 text-center my-4 d-lg-none">
                                    <img className='w-100' src={`/img/applications/${item.image}`} alt={item.title} />
                                </div>
                                <div className="text-black item-content text-center">{item.content}</div>
                            </div>
                        }
                        <div className="col col-12 col-lg-6 text-center my-4 d-lg-block d-none">
                            <img className='w-100' src={`/img/applications/${item.image}`} alt={item.title} />
                        </div>
                        {idx % 2 !== 0 && width >= 992 &&
                            <div className="col col-12 col-lg-6">
                                <div className="item-title text-center fw-bolder">{item.title}</div>
                                <div className="text-black item-content text-center">{item.content}</div>
                            </div>
                        }
                    </div>
                })
            }


        </section>
        <Footer />
    </div>
}