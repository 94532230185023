import { useNavigate } from "react-router-dom";
import "../style/fields.css";

import { ArrowRight, ArrowRightCircle } from "react-bootstrap-icons";

export default function Fields() {
    const navigate = useNavigate();

    const fields = [
        {
            title: "Applications",
            target: "/fields/applications",
            body: ['Mobile', 'Web', 'Desktop', 'APIs'],
        },
        {
            title: "Deployment",
            target: "/fields/deployment",
            body: ['Cloud', 'Dedicated Servers', 'Shared Hosting', 'On-Site'],
        },
        {
            title: "ML/AI",
            target: "/fields/aiml",
            body: ['Chatbots', 'RPAs', 'Data Analysis', 'AI Powered Apps'],
        },
    ];

    return <section id="fields" className="container-fluid py-5 overflow-hidden d-flex flex-column justify-content-around position-relative">
        <div className="text-white top-title p-4 position-relative">Our fields of activity</div>

        <div className="row row-cols-1 row-cols-md-3 px-5">
            {
                fields.map((item, idx) => {
                    return <div key={item.target} className="col px-1 px-md-4">
                        <div className="field text-white text-center my-2">
                            <div className="d-flex flex-column justify-content-around py-5">
                                <div className="title h3 my-5">{item.title}</div>
                                {item.body.map((i) => <div key={i} className="my-1 h6">{i}</div>)}
                            </div>
                            <div className="w-100 text-start p-5">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="icon">
                                        <ArrowRightCircle />
                                    </div>
                                    <div className="action" role="button" aria-label={"Read more about "+item.title} onClick={() => navigate(item.target)}>
                                        <ArrowRight /> Read more
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    </section>;
}
