import { useEffect, useState } from 'react';
import '../style/about.css';

import Footer from "./Footer";
import TopBar from "./TopBar";

import ReactGA from 'react-ga4';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function About() {
    const langs = [
        'r.png',
        'flutter.png',
        'dart.png',
        'js.png',
        'python.png',
        'php.png',
        'go.png',
        'rust.png',
    ];

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        document.getElementById("about")?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        ReactGA.send({hintType: 'pageview', page: location.pathname + location.search, title: 'about'});
    }, [location]);

    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [message, setMessage] = useState("");

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("Thank you! We will come back to you soon!");
    const [modalClass, setModalClass] = useState("text-bg-danger");

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    function submit(e) {
        e.preventDefault();

        let required_fields = [
            fullname,
            email,
        ];

        for (var i = 0; i < required_fields.length; i++) {
            if (required_fields[i].length === 0) {
                setModalMessage("Please fill the required fields and then try again, or send us an email: info@minomint.com !");
                setModalClass('text-bg-danger');
                setShowModal(true);
                setTimeout(() => {setShowModal(false)}, 3000);
                return;
            }
        }

        if (!validateEmail(email)) {
            setModalMessage("Invalid email address...");
            setModalClass('text-bg-danger');
            setShowModal(true);
            setTimeout(() => { setShowModal(false) }, 3000);
            return;
        }

        const payload = {
            'fullname': fullname,
            'email': email,
            'company': company,
            'message': message,
        };

        axios.post("/api/contact", { data: payload })
            .then((res) => {
                if (res.data.error) {
                    setModalMessage("Something went wrong! Try please try again, or send us an email: info@minomint.com !");
                    setModalClass('text-bg-danger');
                } else {
                    setModalMessage("Thank you! We will come back to you soon!");
                    setModalClass('text-bg-success');

                    setFullname("");
                    setEmail("");
                    setCompany("");
                    setMessage("");
                }
                setShowModal(true);
            })

        ReactGA.event({
            category: 'Button Click',
            action: 'Clicked on Submit',
            label: 'Contact Us',
        });
    }

    return <div>
        <TopBar />
        <section id="about" className='p-5'>
            <div className="w-100 h-100 z-2 position-relative">
                <div className="top-title text-white mb-3 pt-5">
                    <span className="about">About</span> us
                </div>

                <div className="row">
                    <div className="col col-12 col-xl-8 mt-5">
                        <div className="position-relative w-100 section-field p-3 pt-5">
                            <div className="section-title position-absolute start-0 end-0 text-center">The Idea</div>
                            <p>
                                In an era overwhelmed by digital noise and distractions, we believe that simplicity is key to enhancing productivity. Our mission is to deliver fully functional and efficient software solutions with a focus on minimal design and smooth user experience. As companies adopt new tools for digital transformation, we aim to make this transition seamless by providing distraction-free solutions, comprehensive documentation, and effective training services. We stay current with our technology stack to ensure our solutions are relevant, flexible, and enhanced with AI for ease of use.
                            </p>
                        </div>
                    </div>

                    <div className="col col-12 col-xl-8 offset-xl-4 mt-5">
                        <div className="position-relative w-100 section-field p-3 pt-5">
                            <div className="section-title position-absolute start-0 end-0 text-center">Our Experience</div>
                            <p>
                                Our team brings over a decade of experience in high-performance software solutions. We've faced the challenges of adapting to new tools and understand the demands it places on employees. With backgrounds in scientific research, academia, education, and software development, we are well-equipped to provide optimal solutions. Our extensive experience ensures we design and implement software that meets industry standards, helping clients confidently navigate digital transformation.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section id="contact" className="p-5" >
            <div className="top-title my-1 fw-bolder">
                Using the latest <span className="gold">technology</span>,<br />
                we deliver the best <span className="gold">quality</span>.
            </div>
            <div className="row">
                <div className="langs-text col-12 col-lg-6 position-relative pt-5">
                    We meticulously choose the technology tools, including programming languages, frameworks, and infrastructure, after a detailed analysis to ensure we optimize our pricing and select the best-fit solutions for our clients. By thoroughly evaluating each project's unique requirements, we align our technology choices with our clients' goals, ensuring cost-effectiveness and high performance. Our commitment to using the most suitable and up-to-date technologies guarantees that our solutions are not only robust and scalable but also tailored to meet the specific needs of each client. This 'win-win or no-deal' approach ensures that we deliver maximum value and satisfaction in every project we undertake.
                    <div className="languages d-flex flex-wrap justify-content-center position-absolute top-0">
                        {
                            langs.map((item) => {
                                return <div key={item}><img src={"img/about/" + item} alt={item} /></div>
                            })
                        }
                    </div>
                    <p className='pt-4'>Want to get updates about our services, products, and more? <a onClick={() => navigate("/subscribe")} className='link-underline-success' role='button'>Subscribe</a> to our newsletter!</p>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="row pt-5">
                        <div className="contact-form rounded p-5 col col-12 col-lg-8 offset-lg-2">
                            <div className="contact-title text-center py-2">Contact us</div>
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="fullname" className="form-label">Fullname <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="fullname" value={fullname} required onChange={(e) => setFullname(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email address <span className="text-danger">*</span></label>
                                    <input type="email" className="form-control" id="email" value={email} required onChange={(e) => setEmail(e.target.value)} />
                                    <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="company" className="form-label">Company</label>
                                    <input type="text" className="form-control" id="company" value={company} onChange={(e) => setCompany(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="message" className="form-label">Message</label>
                                    <textarea className="form-control" id="message" value={message} onChange={(e) => setMessage(e.target.value)} ></textarea>
                                </div>
                                <div className="mb-3">
                                    <div className={"toast text-bg-primary mx-auto " + modalClass + (showModal ? ' show' : '')} role="alert" aria-live="assertive" aria-atomic="true">
                                        <div className="d-flex">
                                            <div className="toast-body">
                                                {modalMessage}
                                            </div>
                                            <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowModal(false)}></button>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary" onClick={submit}>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
}