import "../style/services.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";
import { height } from "@fortawesome/free-regular-svg-icons/faAddressBook";

export default function Services() {
    const [selectedItem, setSelectedItem] = useState(0);

    const options = [
        {
            title: "Development",
            image: "development.png",
            target: "development",
        },
        {
            title: "Training",
            image: "training.png",
            target: "training",
        },
        {
            title: "Consulting",
            image: "consulting.png",
            target: "consulting",
        },
    ];

    const descriptions = [
        `Our Development team excels in creating custom software solutions that cater to your unique business requirements. We specialize in developing web, mobile, and desktop applications with a focus on incorporating minimal AI-powered features. Our goal is to deliver solutions that offer a distraction-free user experience, enhancing efficiency and user satisfaction. From conceptualization to deployment, we ensure that our software solutions are robust, scalable, and aligned with your strategic objectives.`,
        `Our Training services are designed to empower your employees with the knowledge and skills needed to effectively use modern technologies. Whether it's mastering machine learning (ML) and artificial intelligence (AI) or becoming proficient in the latest software development tools, we provide comprehensive training programs tailored to your company's needs. Additionally, we offer specialized training on the software solutions we have implemented for your organization, ensuring seamless integration and maximum productivity.`,
        `Our Consulting services are designed to help your company leverage cutting-edge technologies to solve internal challenges and optimize operations. We work closely with your team to understand your business processes and identify opportunities for improvement. By applying the latest advancements in technology, including AI and ML, we provide strategic insights and practical solutions that drive innovation and enhance operational efficiency. Let us guide you in transforming your business with the power of technology.`,
    ];

    return <section id="services" className="container-fluid py-5 overflow-hidden d-flex flex-column justify-content-around position-relative">
        <div className="watermark-container position-absolute w-100 z-0 text-center">
            <img className="position-relative w-25 z-0" src="img/logo_gray.svg" alt="Logo Watermark" />
        </div>

        <div className="top-title p-4 z-1">Our Services</div>

        <div className="row z-2">
            <div className="col">
                <div className="row d-none d-md-flex">
                    {
                        options.map((item, idx) => {
                            return <div key={item.target} className={"col col-2 bg-white text-center p-0 "+((idx === 0) ? "offset-1" : "offset-2")+((idx === selectedItem) ? " mb-0 rounded-top-4" : " mb-4 rounded-4") } role="button" onClick={() => setSelectedItem(idx)} >
                                    <div className="row">
                                        <div className="col p-4">
                                            <img src={"img/services/" + item.image} alt={item.title} className="w-75" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="title">{item.title}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="position-relative text-center w-100 title my-2">
                                                <FontAwesomeIcon icon={faAnglesDown} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        })
                    }
                </div>
                <div className="row d-flex d-md-none">
                    {
                        options.map((item, idx) => {
                            return <div key={item.target} className={"col col-10 text-center p-0 offset-1 "+((idx === selectedItem) ? " bg-white " : " bg-body-secondary ") } role="button" onClick={() => setSelectedItem(idx)} >
                                    <div className="row align-items-center">
                                        <div className="col col-3 py-2 px-2">
                                            <img src={"img/services/" + item.image} alt={"Picture for "+item.title+" services"} className="w-50" />
                                        </div>
                                        <div className="col text-center">
                                            <div className="title">{item.title}</div>
                                        </div>
                                    </div>
                                </div>
                        })
                    }
                </div>

                <div className="row">
                    <div className="col col-10 offset-1 description p-4 bg-white rounded-bottom service-description-container">
                        <p className="text-center text-secondary">
                            {descriptions[selectedItem]}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>;
}