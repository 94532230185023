import {
    Facebook,
    Instagram,
    Linkedin,
    // TwitterX,
} from "react-bootstrap-icons";

import "../style/footer.css";

export default function Footer() {
    return <div id="footer">
        <div className="d-flex flex-column p-5 justify-content-around h-100">
            <div className="image-container text-center">
                <img src="/img/logo_full.png" alt="minoMint Logo" />
            </div>

            <div className="social text-center">
                <a href="https://www.linkedin.com/company/minomint" target="_blank" rel="noreferrer" aria-label="Visit our LinkedIn Page" ><Linkedin className="mx-2" /></a>
                {/* <a href="https://" target="_blank" rel="noreferrer" aria-label="Visit our Twitter Account><TwitterX className="mx-2" /></a> */}
                <a href="https://www.instagram.com/minomint_software_solutions/" target="_blank" rel="noreferrer" aria-label="Visit our Instagram Account" ><Instagram className="mx-2"/></a>
                <a href="https://www.facebook.com/com.minomint" aria-label="Visit our Facebook Page" target="_blank" rel="noreferrer" ><Facebook className="mx-2" /></a>
            </div>

            <div className="copyright text-center">
                Copyright &copy; 2024 MinoMint. All Rights Reserved.
            </div>
        </div>
    </div>;
}

