import { useNavigate } from "react-router-dom";
import "../style/process.css";

export default function Process() {
    const navigate = useNavigate();

    return <section id="process" className="container-fluid bg-white py-5 overflow-hidden d-flex flex-column justify-content-around position-relative">
        <div className="watermark-container position-absolute w-100 z-0 text-end">
            <img className="position-relative" src="img/watermark.png" alt="Logo Watermark" />
        </div>

        <div className="top-title p-4 z-1 position-relative">How we work</div>

        <div className="description row z-1">
            <div className="col col-12 col-lg-10 p-4">
                At minoMint, transparency and organization are at the heart of our process to ensure smooth collaboration.
                From receiving your request and preparing a tailored offer to arranging details through a call,
                you can track every step of the process online via our web or mobile app. We provide continuous updates
                until final evaluation and deployment, followed by ongoing customer support to meet your needs.
            </div>
        </div>
        <div className="image-container text-center p-4 z-1 position-relative">
            <div className="overflow-x-auto">
                <img className="w-100 min-w-xl" src="img/process/flow.svg" alt="Implementation Phases" />
            </div>
        </div>
        <div className="w-100 d-flex justify-content-center justify-content-lg-end position-relative z-1 px-4">
            <div className="d-block px-5 fw-bold">
                <button type="button" className="btn btn-secondary lets-get-started px-4 py-2 " aria-label="Let's get Started" onClick={() => navigate('/offer')} >
                    Let's get started!
                </button>
            </div>
        </div>
        <div className="position-absolute p-0 m-0 bottom-0 w-100 z-0 d-none d-lg-block" style={{maxHeight: "110px"}}>
            <img className="w-100" src="img/process/bottom-curve.svg" alt="Background Curve" />
        </div>
    </section>;
}
