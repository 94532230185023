import Fields from "./components/Fields";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Partners from "./components/Partners";
import Process from "./components/Process";
import Services from "./components/Services";
import TopBar from "./components/TopBar";

function App() {
  return (
    <div className="App">
      <TopBar />
      <Home />
      <Process />
      <Services />
      <Fields />
      {/* <Partners /> */}
      <Footer />
    </div>
  );
}

export default App;
