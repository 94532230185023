import { useEffect, useState } from 'react';
import '../style/offer.css';
import TopBar from './TopBar';
import Footer from './Footer';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

export default function Offer() {
    const location = useLocation();
    useEffect(() => {
        document.getElementById("offer")?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        ReactGA.send({ hintType: 'pageview', page: location.pathname + location.search, title: 'offer' });
    }, [location]);

    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [services, setServices] = useState("");
    const [description, setDescription] = useState("");
    const [technology, setTechnology] = useState("");
    const [budget, setBudget] = useState("");
    const [timeline, setTimeline] = useState("");

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("Thank you! We will come back to you soon!");
    const [modalClass, setModalClass] = useState("text-bg-danger");

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    function submit(e) {
        e.preventDefault();

        let required_fields = [
            fullname,
            email,
            phone,
        ];

        for (var i = 0; i < required_fields.length; i++) {
            if (required_fields[i].length === 0) {
                setModalMessage("Please fill the required fields and then try again, or send us an email: info@minomint.com !");
                setModalClass('text-bg-danger');
                setShowModal(true);
                setTimeout(() => {setShowModal(false)}, 3000);
                return;
            }
        }

        if (!validateEmail(email)) {
            setModalMessage("Invalid email address...");
            setModalClass('text-bg-danger');
            setShowModal(true);
            setTimeout(() => { setShowModal(false) }, 3000);
            return;
        }

        const payload = {
            'fullname': fullname,
            'email': email,
            'phone': phone,
            'company': company,
            'services': services,
            'description': description,
            'technology': technology,
            'budget': budget,
            'timeline': timeline,
        };

        axios.post("/api/request", { data: payload })
            .then((res) => {
                if (res.data.error) {
                    setModalMessage("Something went wrong! Try please try again, or send us an email: info@minomint.com !");
                    setModalClass('text-bg-danger');
                } else {
                    setModalMessage("Thank you! We will come back to you soon!");
                    setModalClass('text-bg-success');

                    setFullname("");
                    setEmail("");
                    setPhone("");
                    setCompany("");
                    setServices("");
                    setDescription("");
                    setTechnology("");
                    setBudget("");
                    setTimeline("");
                }
                setShowModal(true);
                setTimeout(() => {setShowModal(false)}, 3000);
            })

        ReactGA.event({
            category: 'Button Click',
            action: 'Clicked on Submit',
            label: 'Request Offer',
        });
    }
    return <div className='pt-5 bg-black'>
        <TopBar />
        <section id="offer" className="p-5">
            <div className="row">
                <div className="col col-12 col-xl-4 p-2 pt-5">
                    <div className="top-title mb-3 text-center">
                        Ready to enhance your business
                        <span className="highlight"> with our cutting-edge solutions</span>?
                    </div>
                    <div className="prompt text-white">
                        Fill out the following form to request a personalized offer.
                        Provide as much detail as possible about your needs,
                        and our team will get back to you with a tailored solution.
                        If you have any questions or need immediate assistance,
                        feel free to contact us directly.
                    </div>
                    <div className="contact text-white py-4 text-center">
                        Email: <span className="highlight"><a href="mailto:info@minomint.com">info@minomint.com</a></span>
                    </div>

                </div>
                <div className="col col-12 col-xl-8 p-2">
                    <div className="row pt-5">
                        <div className="contact-form rounded p-5 col col-12 col-lg-8 offset-lg-2">
                            <div className="contact-title text-center py-2">Request an Offer</div>
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="fullname" className="form-label">Fullname <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="fullname" value={fullname} required onChange={(e) => setFullname(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email address <span className="text-danger">*</span></label>
                                    <input type="email" className="form-control" id="email" value={email} required onChange={(e) => setEmail(e.target.value)} />
                                    <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="phone" className="form-label">Phone Number <span className="text-danger">*</span></label>
                                    <input type="phone" className="form-control" id="phone" value={phone} required onChange={(e) => setPhone(e.target.value)} />
                                    <div id="phoneHelp" className="form-text">We'll never share your phone with anyone else.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="company" className="form-label">Company</label>
                                    <input type="text" className="form-control" id="company" value={company} onChange={(e) => setCompany(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="services" className="form-label">Services Needed</label>
                                    <input type="text" className="form-control" id="services" value={services} placeholder='Training, Development, Consulting' onChange={(e) => setServices(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">Project Description</label>
                                    <textarea className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} ></textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="technology" className="form-label">Preferred Technology Stack (if any)</label>
                                    <input type="text" className="form-control" id="technology" value={technology} onChange={(e) => setTechnology(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="budget" className="form-label">Budget Range</label>
                                    <input type="text" className="form-control" id="budget" value={budget} onChange={(e) => setBudget(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="timeline" className="form-label">Timeline</label>
                                    <input type="text" className="form-control" id="timeline" value={timeline} onChange={(e) => setTimeline(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <div className={"toast text-bg-primary mx-auto " + modalClass + (showModal ? ' show' : '')} role="alert" aria-live="assertive" aria-atomic="true">
                                        <div className="d-flex">
                                            <div className="toast-body">
                                                {modalMessage}
                                            </div>
                                            <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowModal(false)}></button>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary" onClick={submit} >Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
}