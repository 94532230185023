import "../style/home.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import Logo from "./Logo";
import { useNavigate } from "react-router-dom";
import { SendFill } from "react-bootstrap-icons";

function scrollToElement(element_id) {
    document.getElementById(element_id)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
}

export default function Home() {
    const navigate = useNavigate();

    return <section id="home" className="container-fluid bg-black py-5 z-2 position-relative w-100 overflow-hidden d-flex flex-column justify-content-around">
    {/* return <section id="home" className="container-fluid bg-black py-5 z-2 position-relative w-100"> */}
        <div className="row py-5 position-relative">
            <div className="col col-12 col-lg-6 py-6 home-text text-center">
                <div className="d-flex align-items-center justify-content-center flex-column h-100">
                    <div className="title px-4">
                        <span className="we-create">We create</span> high quality applications
                        focused on distraction free, minimal, fully functional experience.
                    </div>
                </div>
            </div>
            <div className="col col-12 col-lg-6 text-center py-5">
                <Logo />
            </div>
            <div className="request-offer px-2 py-2 d-block d-lg-none mx-auto mb-5 rounded text-center" role="button" onClick={() => navigate("/offer")}>
                <div className="py-2 text-nowrap">
                    <span className="send-icon mx-2"><SendFill /></span>
                    REQUEST AN OFFER
                </div>
            </div>
            <div className="position-absolute text-center w-100 text-white h3 godown">
                <FontAwesomeIcon icon={faAnglesDown} role="button" onClick={() => scrollToElement("process")} />
            </div>
        </div>
    </section>;
}