import "../style/topbar.css";

import { List, SendFill } from "react-bootstrap-icons";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';

export default function TopBar() {
    const [selectedItem, setSelectedItem] = useState(0);
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const menu_options = [
        {
            title: "Home",
            target: "home",
        },
        {
            title: "How We Work",
            target: "process",
        },
        {
            title: "Services",
            target: "services",
        },
        {
            title: "Fields of Activity",
            target: "fields",
        },
        // {
        //     title: "Partners",
        //     target: "partners",
        // },
        {
            title: "About Us",
            target: "/about",
        },
    ];

    function scrollToElement(element_id) {
        if (element_id.includes('/')) {
            navigate(element_id);
        } else if (location.pathname !== '/') {
            navigate('/');

            setTimeout(() => {
                document.getElementById(element_id)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                setShowMenu(false);
            }, 300);
        } else {
            document.getElementById(element_id)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            setShowMenu(false);
        }
        ReactGA.send({hintType: 'pageview', page: location.pathname + "#" + element_id, title: element_id});
    }

    function updateSelectedItem() {
        let h = window.innerHeight;

        const items = menu_options.filter((item) => !item.target.includes("/"));

        for (var i = 0; i < items.length; i++) {
            let el = document.getElementById(items[i].target);
            if (el === null) continue;

            const { top } = el.getBoundingClientRect();

            if (top + (h / 3) > 0) {
                setSelectedItem(i);
                return;
            }
        }
        setSelectedItem(items.length - 1);
        return;
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            updateSelectedItem();
        });
        updateSelectedItem();
    })

    return <nav id="topbar" className="navbar fixed-top bg-black bg-body-black navbar-expand-lg py-0">
        <div className="container-fluid pe-lg-0">
            <div className="navbar-brand logo" role="button" onClick={() => scrollToElement("home")}>
                <img src="/img/logo_full.png" alt="minoMint" className="top-logo" />
            </div>
            <div className="navbar-toggler text-white" role="button" aria-label="Main Menu" onClick={() => setShowMenu(!showMenu)}>
                <List />
            </div>
            <div className={"collapse navbar-collapse text-center " + ((showMenu) ? "show" : "")} id="main-menu">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 menu-options align-items-center px-auto mx-auto">
                    {
                        menu_options.map((item, idx) => {
                            let divider = (idx < menu_options.length - 1) ? ' | ' : '';
                            if ((idx === selectedItem && location.pathname === '/') ||
                                location.pathname.includes(item.target)) {
                                return <>
                                    <li className="nav-item menu-option px-2 py-2 py-lg-0 active" role="button" onClick={() => { setSelectedItem(idx); scrollToElement(item.target); }}>
                                        {item.title}
                                    </li>
                                    <li className="divider d-none d-lg-block px-2">{divider}</li>
                                </>;
                            }
                            return <>
                                <li className="nav-item menu-option px-2 py-2 py-lg-0" role="button" onClick={() => { setSelectedItem(idx); scrollToElement(item.target); }}>
                                    {item.title}
                                </li>
                                <li className="divider d-none d-lg-block px-2">{divider}</li>
                            </>;
                        })
                    }
                </ul>
                <div className={"request-offer px-2 py-2 px-xl-4 d-none d-lg-block" + (location.pathname.includes('offer') ? " current" : "")} role="button" onClick={() => navigate("/offer")}>
                    <div className="py-2 text-nowrap">
                        <span className="send-icon mx-2"><SendFill /></span>
                        REQUEST AN OFFER
                    </div>
                </div>
            </div>
        </div>
    </nav>;
}