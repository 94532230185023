import { useNavigate, useRouteError } from "react-router-dom";

import "../style/error.css";
import { useEffect } from "react";

export default function ErrorPage() {
    const error = useRouteError();
    const navigate = useNavigate();


    return (<div id="error" className="p-4">

        <h1 className="section-title">Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p className="section-subtitle">
            <i>{error.statusText || error.message}</i>
        </p>
        <button className="btn btn-secondary" onClick={() => navigate('/')}>Back to home...</button>
    </div>
    );
}