import { useEffect, useState } from 'react';
import '../style/deployment.css';
import TopBar from './TopBar';
import Footer from './Footer';

import ReactGA from 'react-ga4';

export default function Deployment() {
    const [width, setWidth] = useState(0);

    const items = [
        {
            title: "Cloud Deployment",
            content: "Our team selects the optimal cloud platforms to deploy your applications, offering scalability, flexibility, and efficiency to address your business demands.",
            image: "cloud.png",
        },
        {
            title: "Dedicated Servers",
            content: "For businesses requiring high performance, security, and control, we choose the best dedicated server solutions to meet your requirements.",
            image: "dedicated.png",
        },
        {
            title: "Shared Hosting",
            content: "We identify cost-effective shared hosting options for smaller projects or businesses with moderate traffic, ensuring reliability and support.",
            image: "shared.png",
        },
    ];

    function updateWidth() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        document.getElementById("deployment")?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        updateWidth();
        window.addEventListener('resize', () => {
            updateWidth();
        });
        ReactGA.send({hintType: 'pageview', page: '/fields/deployment', title: 'Deployment'});
    });
    return <div className='pt-5'>
        <TopBar />
        <section id="deployment" className="container-fluid py-5 overflow-hidden d-flex flex-column justify-content-around position-relative">

            <div className="top-title mb-3 p-4">
                We design, implement, and deploy <span className="highlight">Applications </span>
                based on your needs
            </div>

            {
                items.map((item, idx) => {
                    return <div key={item.image} className="row p-4">
                        {(idx % 2 === 0 || width < 992) &&
                            <div className="col col-12 col-lg-6">
                                <div className="item-title text-center fw-bolder">{item.title}</div>
                                <div className="col col-12 col-lg-6 text-center my-4 d-lg-none">
                                    <img className='w-100' src={`/img/deployment/${item.image}`} alt={item.title} />
                                </div>
                                <div className="text-black item-content text-center">{item.content}</div>
                            </div>
                        }
                        <div className="col col-12 col-lg-6 text-center my-4 d-lg-block d-none">
                            <img className='w-100' src={`/img/deployment/${item.image}`} alt={item.title} />
                        </div>
                        {idx % 2 !== 0 && width >= 992 &&
                            <div className="col col-12 col-lg-6">
                                <div className="item-title text-center fw-bolder">{item.title}</div>
                                <div className="text-black item-content text-center">{item.content}</div>
                            </div>
                        }
                    </div>
                })
            }


        </section>
        <Footer />
    </div>
}