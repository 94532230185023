import { useEffect, useState } from 'react';
import '../style/subscribe.css';

import Footer from "./Footer";
import TopBar from "./TopBar";
import Logo from "./Logo";

import ReactGA from 'react-ga4';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Subscribe() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        document.getElementById("subscribe")?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        ReactGA.send({hintType: 'pageview', page: location.pathname + location.search, title: 'about'});
    }, [location]);

    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [options, setOptions] = useState({
        "offers": false,
        "products": false,
        "services": false,
        "courses": false,
    });

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("Thank you! We will come back to you soon!");
    const [modalClass, setModalClass] = useState("text-bg-danger");

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    function submit(e) {
        e.preventDefault();

        let required_fields = [
            first_name,
            last_name,
            email,
        ];

        for (var i = 0; i < required_fields.length; i++) {
            if (required_fields[i].length === 0) {
                setModalMessage("Please fill the required fields and then try again, or send us an email: info@minomint.com !");
                setModalClass('text-bg-danger');
                setShowModal(true);
                setTimeout(() => {setShowModal(false)}, 3000);
                return;
            }
        }

        if (!validateEmail(email)) {
            setModalMessage("Invalid email address...");
            setModalClass('text-bg-danger');
            setShowModal(true);
            setTimeout(() => { setShowModal(false) }, 3000);
            return;
        }

        const payload = {
            'first_name': first_name,
            'last_name': last_name,
            'email': email,
            'options': options,
        };

        axios.post("/api/subscribe", { data: payload })
            .then((res) => {
                if (res.data.error) {
                    setModalMessage("Something went wrong! Try please try again, or send us an email: info@minomint.com !");
                    setModalClass('text-bg-danger');
                } else {
                    setModalMessage("Thank you! You have subscribed sucessfully!");
                    setModalClass('text-bg-success');

                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setOptions(Object.assign({}, {
                        "offers": false,
                        "products": false,
                        "services": false,
                        "courses": false,
                    }))

                    setTimeout(() => navigate("/"), 8000)
                }
                setShowModal(true);
            })

        ReactGA.event({
            category: 'Button Click',
            action: 'Clicked on Submit',
            label: 'Subscribe',
        });
    }

    return <div>
        <TopBar />
        <section id="subscribe" className="bg-black pt-4" >
            <div className="row py-4" style={{height:"100vh"}}>
                <div className="col-12 col-lg-6 p-5 d-none d-lg-flex align-items-center justify-content-center">
                    <div className="">
                        <div className="w-100 text-end py-auto">
                            <Logo className="w-25" />
                        </div>
                        <img src="/img/logo_full.png" alt="minoMint" className="bottom-logo" />
                    </div>
                </div>
                <div className="col-12 col-lg-6 p-5 d-flex align-items-center justify-content-center bg-mm-secondary" >
                    <div className="row">
                        <div className="subscribe-form col col-12">
                            <div className="subscribe-title text-center py-2">Stay in the Loop with Our Exclusive Updates!</div>
                            <div className="subscribe-text text-center py-2">
                                <p>
                                    Don’t miss out on important announcements from our platform! Subscribe to receive updates on our newest courses, products, services, and special offers. We’ll only reach out when there’s something big to share, so you’ll always stay informed about the things that matter most to you.
                                </p>
                            </div>
                            <form className='col-lg-8 offset-lg-2'>
                                <div className="mb-3">
                                    <label htmlFor="first_name" className="form-label">First Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="first_name" value={first_name} required onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="last_name" className="form-label">Last Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="last_name" value={last_name} required onChange={(e) => setLastName(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email address <span className="text-danger">*</span></label>
                                    <input type="email" className="form-control" id="email" value={email} required onChange={(e) => setEmail(e.target.value)} />
                                    <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Choose the updates you'd like to receive:</label>
                                    <div className="row px-5">
                                        <div className="col col-12 col-lg-6 p-0 m-0">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="courses" checked={options.courses} onClick={() => {options.courses = !options.courses; setOptions(Object.assign({}, options))}}/>
                                                <label class="form-check-label" for="courses">Courses</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="new_services" checked={options.services} onClick={() => {options.services = !options.services; setOptions(Object.assign({}, options))}}/>
                                                <label class="form-check-label" for="new_services">Services</label>
                                            </div>
                                        </div>
                                        <div className="col col-12 col-lg-6 p-0 m-0">

                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="products" checked={options.products} onClick={() => {options.products = !options.products; setOptions(Object.assign({}, options))}}/>
                                                <label class="form-check-label" for="products">Products</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="offers" checked={options.offers} onClick={() => {options.offers = !options.offers; setOptions(Object.assign({}, options))}}/>
                                                <label class="form-check-label" for="offers">Offers</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className={"toast text-bg-primary mx-auto " + modalClass + (showModal ? ' show' : '')} role="alert" aria-live="assertive" aria-atomic="true">
                                        <div className="d-flex">
                                            <div className="toast-body">
                                                {modalMessage}
                                            </div>
                                            <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowModal(false)}></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="button" className="btn btn-subscribe" onClick={submit}>Subscribe</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
}